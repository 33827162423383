import { createSlice } from '@reduxjs/toolkit';
import { options, options_mod_study_value } from '../constant';

const selectedModStudySlice = createSlice({
  name: 'modStudy',
  initialState: {
    selectedModStudy: sessionStorage.getItem('selectedModStudy') && sessionStorage.getItem('selectedModStudy') !== "undefined" ? JSON.parse(sessionStorage.getItem('selectedModStudy')) : options[0],
    selectedModStudyValue : sessionStorage.getItem('selectedModStudy') && sessionStorage.getItem('selectedModStudy') !== "undefined" ? options_mod_study_value[JSON.parse(sessionStorage.getItem('selectedModStudy'))] : options_mod_study_value[options[0]],
  },
  reducers: {
    addSelectedModStudy: (state, action) => {
      sessionStorage.setItem('selectedModStudy', JSON.stringify(action.payload));
      state.selectedModStudy = action.payload;
      state.selectedModStudyValue = options_mod_study_value[action.payload];
    }
  },
});

export const { addSelectedModStudy } = selectedModStudySlice.actions;

export default selectedModStudySlice.reducer;