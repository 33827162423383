import { createSlice } from '@reduxjs/toolkit';

const reportContentSlice = createSlice({
  name: 'reportContents',
  initialState: {
    reportContents: [],
    isChatBotActive: false,
    isChatQuestionActive: false
  },
  reducers: {
    addReport: (state, action) => {
      state.reportContents.push(action.payload);
    },
    removeReport: (state, action) => {
      state.reportContents = state.reportContents.filter(item => item.pathology !== action.payload);
      if (state.reportContents.length > 0) {
        state.reportContents[state.reportContents.length - 1].isLast = false;
      }
    },
    changeIsLast: (state, action) => {
      const report = state.reportContents.find(item => item.pathology === action.payload.pathology);
      if (report) {
        report.isLast = action.payload.isLast;
      }
      state.reportContents.forEach(item => {
        if (item.pathology !== action.payload.pathology) {
          item.isLast = false;
        }
      });
    },
    addAddtionalObservation: (state, action) => {
      const report = state.reportContents.find(item => item.pathology === action.payload.pathology);
      if (report) {
        report.observation = report.observation_init ? (report.observation_init.endsWith('.') ? report.observation_init.slice(0, -1) : report.observation_init) + action.payload.observation : action.payload.observation;
      }
    },
    replaceAddtionalObservation: (state, action) => {
      const report = state.reportContents.find(item => item.pathology === action.payload.pathology);
      if (report) {
        report.observation = action.payload.observation;
      }
    },
    replaceAdditionalImpression: (state, action) => {
      const report = state.reportContents.find(item => item.pathology === action.payload.pathology);
      if (report) {
        report.impression = action.payload.impression;
      }
    },
    makeIsToBeQuestionedFalse: (state, action) => {
      const report = state.reportContents.find(item => item.pathology === action.payload);
      if (report) {
        report.isToBeQuestioned = false;
      }
    },
    resetContent: (state, action) => {
      state.reportContents = action.payload
    },
    flipChatQuestion: (state, action) => {
      state.isChatQuestionActive = action.payload
    },
    flipChatBot: (state) => {
      console.log(state.isChatBotActive, 'kdnqinfiqnwidn')
      state.isChatBotActive = !state.isChatBotActive;
    }
  },
});

export const { addReport, removeReport, resetContent, changeIsLast, makeIsToBeQuestionedFalse, addAddtionalObservation, replaceAddtionalObservation, flipChatQuestion, replaceAdditionalImpression, flipChatBot } = reportContentSlice.actions;

export default reportContentSlice.reducer;