import { createSlice } from '@reduxjs/toolkit';

const augnitoSlice = createSlice({
  name: 'augnito',
  initialState: {
    cursorPosition : 'textArea',
    quillDictate: '',
    textAreaDictate : '',
    sizeInputDictate : '',
    notEditableData: '',
    augnitoData:'',
    valueFromAugntio:'',
    augnitoState: '',
    isListening:false,
    isWhisper:true,
    whisperData:'',
    isConnection:false
    },
  reducers: {
    setCursorPosition: (state, action) => {
        state.cursorPosition = action.payload;
      },
    setQuill: (state, action) => {
      state.quillDictate = action.payload;
    },
    setTextArea: (state, action) => {
        if(action.payload.action === 'reset') state.textAreaDictate = action.payload.text;
        else state.textAreaDictate  += ' '+action.payload.text;
    },
    setSizeInput: (state, action) => {
        if(action.payload.action === 'reset') state.sizeInputDictate = action.payload.text;
        else state.sizeInputDictate  += ' '+action.payload.text;
    },
    setNotEditableData: (state, action) => {
      if(action.payload.action === 'reset') state.notEditableData = action.payload.text;
      else state.notEditableData  += ' '+action.payload.text;
  },
    setAugnitoData:(state,action)=>{
      state.augnitoData = action.payload;
    },
    setDataFromAugnito:(state,action)=>{
      state.valueFromAugntio = action.payload;
    },
    toggleAugnitoState: (state) => {
      state.augnitoState = !state.augnitoState;
    },
    augnitoStartListening: (state) => {
      state.isListening = true;
    },
    augnitoStopListening: (state) => {
      state.isListening = false;
    },
    whisperStartListening:(state)=>{
      state.isWhisper=true;
    },
    whisperStopListening:(state)=>{
      state.isWhisper=false
    },
    setWhisperData:(state,action)=>{
      state.whisperData=action.payload
    },
    setHanldeConnectionOn:(state)=>{
      state.isConnection=true;
    },
    setHanldeConnectionoff:(state)=>{
      state.isConnection=false;
    }
    
  },
});

export const { setCursorPosition, setQuill, setTextArea, setSizeInput, setNotEditableData,setAugnitoData,setDataFromAugnito,toggleAugnitoState, augnitoStartListening,augnitoStopListening,whisperStartListening,whisperStopListening,setWhisperData,setHanldeConnectionOn,setHanldeConnectionoff} = augnitoSlice.actions;

export default augnitoSlice.reducer;