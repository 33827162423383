// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducerLogin from './Login';
import userReducerReport from './ReportContents';
import userChatMessages from './ChatMessages';
import userSelectedPathologies from './SelectedPathologies';
import userSelectedModStudy from './SelectedModStudy';
import pathologyFormData from './PathologyFormData';
import augnito from './Augnito'

export const store = configureStore({
  reducer: {
    user: userReducerLogin,
    reportContents : userReducerReport,
    chatMessages : userChatMessages,
    selectedPathologies : userSelectedPathologies,
    selectedModStudy : userSelectedModStudy,
    pathologyFormData: pathologyFormData,
    augnito : augnito,
  },
});