import logo from '../assets/logo.png';
import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { logoutUser } from '../redux/Login';
import { flipChatBot } from '../redux/ReportContents';
import userlogo from '../assets/kalyan.jpeg';

function Navbar() {
    const dispatch = useDispatch();
    const [isMenuVisible, setMenuVisible] = useState(false);
    const handleProfile = () => {
        setMenuVisible(!isMenuVisible);
    };

    const handleOutsideClick = (event) => {
        if (isMenuVisible && !event.target.closest('.flex-none')) {
          setMenuVisible(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, [isMenuVisible]);
    


    return (

        <div class="navbar bg-blue-50 pl-6">
            <div class="flex-1">
                <img src={logo} alt="" class="h-10 mr-6 rounded-full" />
                <p class="text-2xl font-bold">Bionic Report</p>
            </div>

            <div class="flex-none gap-2 relative">
  <img
    style={{ borderRadius: "50%" }}
    className="w-12 h-12"
    alt=""
    src={userlogo}
    onClick={handleProfile}
  />
  {isMenuVisible && (
    <div
      className="absolute right-0 top-full mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
    >
      <div className="py-1" role="none">
        <a
          className="text-gray-700 block px-4 py-2 border-b text-sm"
          role="menuitem"
          id="menu-item-0"
        >
          Profile
        </a>
        <button
          type="button"
          className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
          role="menuitem"
          id="menu-item-3"
          onClick={() => {
            dispatch(logoutUser());
          }}
        >
          Log Out
        </button>
      </div>
    </div>
  )}
</div>
        </div>

    )
};

export default Navbar;