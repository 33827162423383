
import React, { useEffect, useState } from 'react';
import botlogo from '../assets/bot-logo.png';
import userlogo from '../assets/kalyan.jpeg';
import { useSelector, useDispatch } from 'react-redux';
import { flipIsReportable, flipIsGenerating } from '../redux/SelectedPathologies';
import { addMessage, replaceMessage } from '../redux/ChatMessages';
import { makeIsToBeQuestionedFalse, replaceAddtionalObservation, changeIsLast, flipChatQuestion, replaceAdditionalImpression } from '../redux/ReportContents';
import { updatePathologyFormData } from '../redux/PathologyFormData';
import { getVaildVariables, getAdditionalText, renderInput } from './Helper';

function Questions() {
  const dispatch = useDispatch();
  const [measurementSize, setMeasurementSize] = useState({ CC: '', AP: '', TR: '' });

  const { reportContents, isChatBotActive, messages, selectedPathologies, pathologyFormData, isChatQuestionActive } = useSelector(state => ({
    reportContents: state.reportContents.reportContents,
    isChatBotActive: state.reportContents.isChatBotActive,
    messages: state.chatMessages.messages,
    selectedPathologies: state.selectedPathologies.selectedPathologies,
    pathologyFormData: state.pathologyFormData.pathologyFormData,
    isChatQuestionActive: state.reportContents.isChatQuestionActive,

  }));

  useEffect(() => {
    const inputScroll = document.getElementById('inputScroll');
    if (inputScroll) {
      inputScroll.scrollTop = inputScroll.scrollHeight;
    }
  }, [messages]);



  const handlePathologyFormDataChange = (key, value, pathology) => {
    dispatch(updatePathologyFormData({ key, value, pathology }));
  };

  const updateMeasurementSize = (event, key, dimension, pathology) => {
    const value = event.target.value;
    setMeasurementSize(prevMeasurementSize => {
      const newMeasurementSize = { ...prevMeasurementSize, [dimension]: value };
      handlePathologyFormDataChange(key, `${newMeasurementSize.CC} x ${newMeasurementSize.AP} x ${newMeasurementSize.TR}`, pathology)
      return newMeasurementSize;
    });
  };

  const isFormValidForSubmmit = (pathology) => {
    const reportContent = reportContents.find(content => content.pathology === pathology);
    if (!reportContent || !pathologyFormData) {
      return false;
    }
    const vaildVariables = getVaildVariables(reportContent.question, pathologyFormData[pathology]);

    const formDataKeys = Object.keys(pathologyFormData?.[pathology] || {});
    if (vaildVariables.every(variable => formDataKeys.includes(variable))) {
      return true;
    }
    return false;
  };

  const handleSubmit = (event, pathology) => {
    event.preventDefault();
    dispatch(flipChatQuestion(false));
    dispatch(replaceMessage([]));
    reportContents.forEach(content => {
      if (content.pathology === pathology) {
        let addtionalObservation = (content.observation_init.endsWith('.') ? content.observation_init.slice(0, -1) : content.observation_init) + ' ' + getAdditionalText(content.question, pathologyFormData[content.pathology])

        for (const [key, value] of Object.entries(pathologyFormData[content.pathology])) {
          if (addtionalObservation.includes(`_${key}`)) {
            addtionalObservation = addtionalObservation.replace(new RegExp(`_${key}`, 'g'), value);
          }
        }

        dispatch(replaceAddtionalObservation({ 'pathology': pathology, 'observation': addtionalObservation }))

        let additionalImpression = content.impression_init;

        for (const [key, value] of Object.entries(pathologyFormData[content.pathology])) {
          if (additionalImpression.includes(`_${key}`)) {
            additionalImpression = additionalImpression.replace(new RegExp(`_${key}`, 'g'), value);
          }
        }
        dispatch(replaceAdditionalImpression({ 'pathology': pathology, 'impression': additionalImpression }))

        dispatch(changeIsLast({ 'pathology': pathology, 'isLast': true }))
      }
    });

    dispatch(flipIsReportable(true))
    dispatch(flipIsGenerating(true))
  }


  const renderForm = (message) => {
    return (
      <form id={message.pathology} onSubmit={(event) => { handleSubmit(event, message.pathology); }}>
        {message.question.map((input) => renderInput(input, message.variable, message.pathology, handlePathologyFormDataChange, measurementSize, updateMeasurementSize, pathologyFormData))}
        <button className={`inline-flex items-center justify-center rounded-lg focus:outline-none order-2 ${isFormValidForSubmmit(message.pathology) ? 'transition-transform transform scale-150' : ''}`} disabled={!isFormValidForSubmmit(message.pathology)} style={{ float: 'right' }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 20 20" fill="currentColor" className="ml-2 h-4 w-4 rotate-90 transform">
            <path fill="black" d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
          </svg>
        </button>
      </form>
    );
  }


  useEffect(() => {
    if (reportContents?.length > 0 && isChatBotActive) {
      const lastEnteredPathologyContent = reportContents[reportContents.length - 1];
      if (lastEnteredPathologyContent.isToBeQuestioned) {
        dispatch(addMessage({ 'sender': 'Bot', 'text': lastEnteredPathologyContent, 'question': true }))
        dispatch(makeIsToBeQuestionedFalse(lastEnteredPathologyContent.pathology))

      }
    }
    else if (reportContents && reportContents.length > 0) {
      dispatch(flipIsReportable(true))
      dispatch(flipIsGenerating(true))

    }

  }, [selectedPathologies]);

  return (
    <div>
      {messages.map((message, index) => {
        // const prevMessage = index > 0 ? messages[index + 1] : null;
        // const showLogo = prevMessage?.sender !== message.sender;

        return (
          <div key={index} className="chat-messages" >
            {message.sender === 'You' ? (
              <div className="chat chat-end">
                {/* <div className="chat-image avatar">
            <div className="w-10 rounded-full">
              {showLogo && (
                <img  alt=""  src={userlogo} />
              )}
              {!showLogo && <div className="w-10 h-10 bg-white"></div>}
            </div>
          </div> */}
                {/* <div className="chat-bubble bg-blue-50 text-black">{message.text}</div> */}
              </div>
            ) : (
              <div className="flex items-end">
                <div className="chat chat-start">
                  {/* <div className="chat-image avatar">
              <div className="w-10 rounded-full">
                {showLogo && <img alt="" src={botlogo}></img>}
                {!showLogo && <div className="w-10 h-10 bg-white"></div>}
              </div>
            </div> */}

                  {message?.question && (
                    <div className='chat-bubble bg-blue-100 text-black'>
                      {renderForm(message?.text)}
                      {/* <div>{console.log(message?.text)}</div> */}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Questions;