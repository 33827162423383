import { createSlice } from '@reduxjs/toolkit';

const pathologyFormData = createSlice({
  name: 'pathologyFormData',
  initialState: {
    pathologyFormData: {},
  },
  reducers: {
    setPathologyFormData: (state, action) => {
      state.pathologyFormData = { ...state.pathologyFormData, ...action.payload };
    },
    updatePathologyFormData: (state, action) => {
      const { key, value, pathology } = action.payload;
      state.pathologyFormData[pathology] = {
        ...state.pathologyFormData[pathology],
        [key]: value,
      };
    },
    resetPathologyFormData: (state, action) => {
      state.pathologyFormData = action.payload
    }
  },
});

export const { setPathologyFormData, updatePathologyFormData, resetPathologyFormData } = pathologyFormData.actions;
export default pathologyFormData.reducer;