import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from './Modal';

const FormData = require('form-data');
let refAudio;

const stream = async (setPermissionDenied) => {
    try {
        const temp = await navigator.mediaDevices.getUserMedia({ audio: true });
        refAudio = new MediaRecorder(temp);
    } catch (error) {
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
            setPermissionDenied(true);
            // console.log("Permission Denied");
        } else {
            console.error('Error accessing media devices:', error);
        }
    }
};

function AudioCapture({ sendMessageStream }) {
    const [isRecording, setIsRecording] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const whisperState = useSelector((state) => state.augnito.isWhisper);
    const isEnabled = useSelector((state) => state?.user?.isEnabled);

    useEffect(() => {
        stream(setPermissionDenied);
    }, []);

    const startRecording = async () => {
        try {
            if (refAudio) {
                console.log("start recording function");
                refAudio.start();
                setIsRecording(true);
            }
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (refAudio) {
            console.log("stop recording function");
            refAudio.stop();
            refAudio.ondataavailable = (event) => {
                transcribeAudio(event.data);
            };
            setIsRecording(false);
        }
    };

    const transcribeAudio = (audioData) => {
        let data = new FormData();
        const fileName = `audio_${new Date().toISOString()}.wav`;

        data.append('file', new File([audioData], fileName));
        data.append('model', 'whisper-1');
        data.append('language', 'en');
        data.append('response_format', 'text');
        data.append('temperature', 0.01);
        data.append('prompt', "Return words confined to radiology. No bye or thank you or good luck at the end");

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_BIONIC_BACKEND_API_TOKEN}`);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow',
        };

        fetch(`${process.env.REACT_APP_WHISPER_BACKEND}/api/speech-to-text`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data !== null) {
                    sendMessageStream(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (whisperState) {
                stopRecording();
                startRecording();
            }
        }, 2500);

        return () => clearInterval(interval);
    }, [whisperState]);

    return (
        <>
           
           <Modal
    title="Test Modal"
    message="This is a test to see if the modal renders."
    onClose={() => {}}
/>

            
            
            <button
                onClick={whisperState ? stopRecording : startRecording}
                type="button"
                className="inline-flex h-12 w-12 items-center justify-center rounded-full text-blue-500 transition duration-500 ease-in-out active:bg-blue-300 active:outline-10px-dashed-blue"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="blue"
                    viewBox="0 0 24 24"
                    stroke={whisperState ? "blue" : "grey"}
                    className="h-8 w-8 items-center justify-center rounded-full text-blue-500 transition duration-500 ease-in-out active:bg-blue-300"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={whisperState ? "blue" : "grey"}
                        strokeWidth="2"
                        d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                    />
                </svg>
            </button>
        </>
    );
}

export default AudioCapture;
