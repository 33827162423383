import { createSlice } from '@reduxjs/toolkit';

const chatMessageSlice = createSlice({
  name: 'chatMessages',
  initialState: {
    messages: [{ text: `Hello Doc, I am here to assist you on  reporting!! Please write down the pathologies. `, sender: "Bot" }],
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages = [action.payload];
    },
    resetMessage: (state) => {
      state.messages = [{ text: `Hello Doc, I am here to assist you on  reporting!! Please write down the pathologies. `, sender: "Bot" }]
    },
    replaceMessage: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const { addMessage, resetMessage, replaceMessage } = chatMessageSlice.actions;

export default chatMessageSlice.reducer;