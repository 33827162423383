import React, { useState, useRef, useEffect } from 'react';
import { options } from '../constant';
import { useSelector, useDispatch } from 'react-redux';
import { addSelectedModStudy } from '../redux/SelectedModStudy'
import { removeReport } from '../redux/ReportContents'
import { resetMessage } from '../redux/ChatMessages'
import { resetSelectedPathology } from '../redux/SelectedPathologies'



export default function CustomDropdown() {
    const selectedModStudy = useSelector(state => state.selectedModStudy.selectedModStudy);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);

    const handleSelect = (option) => {
        dispatch(addSelectedModStudy(option));
        dispatch(resetMessage());
        dispatch(removeReport([]));
        dispatch(resetSelectedPathology());
        setIsOpen(false);
    };

    return (
        <div className="relative w-[40%] m-2" ref={dropdownRef}>
            <div
                className=" py-2 rounded-lg cursor-pointer text-sm font-bold flex items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`h-6 w-6 mr-2 transform ${isOpen ? 'rotate-180' : ''}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
                {/* {selectedModStudy.length > 5 ? `${selectedModStudy.substring(0, 5)}...` : selectedModStudy} */}
                {selectedModStudy}
            </div>
            {isOpen && (
                <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded shadow-lg">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

