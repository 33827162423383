import { createSlice } from '@reduxjs/toolkit';

const selectedPathologiesSlice = createSlice({
  name: 'pathologies',
  initialState: {
    selectedPathologies: [],
    isReportable: false,
    isGenerating :false
  },
  reducers: {
    addPathology: (state, action) => {
      state.selectedPathologies = state.selectedPathologies || [];
      if (!state.selectedPathologies.includes(action.payload)) {
        state.selectedPathologies.push(action.payload);
      }
    },
    
    removePathology: (state, action) => {
      state.selectedPathologies = state.selectedPathologies.filter(item => item !== action.payload);
    },
    resetSelectedPathology: (state, action) => {
      state.selectedPathologies = action.payload
    },
    flipIsReportable: (state, action) => {
      state.isReportable = action.payload
    },
    flipIsGenerating: (state, action) => {
      state.isGenerating = action.payload
    },
  },
});



export const { addPathology, removePathology, resetSelectedPathology, flipIsReportable, flipIsGenerating, getLastPathology } = selectedPathologiesSlice.actions;

export default selectedPathologiesSlice.reducer;