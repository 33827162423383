import React, { useEffect, useState } from 'react';
import Dictate from './Dictate';
import Questions from './Question';
import Navbar from './Navbar';
import Editor from './Editor';
import { loginSuccess } from '../redux/Login';
import Login from './Login';
import CustomDropdown from './OptionDropDown';
import MessageInput from './MessageInput';
import { useSelector, useDispatch } from 'react-redux';
import Dicom from './Dicom'
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ctabdomen from '../assets/ct_abdomen.png';

function getQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const accessToken = searchParams.get('accessToken');
  const idToken = searchParams.get('idToken');

  return { accessToken, idToken };
}

function MainChatContainer() {
  const { accessToken, idToken } = getQueryParams();
  const user = useSelector((state) => state.user);
  const isChatQuestionActive = useSelector((state) => state.reportContents.isChatQuestionActive);
  const messages = useSelector((state) => state.chatMessages.messages);
  const isChatBotActive = useSelector((state) => state.reportContents.isChatBotActive);
  const augnitoValue = useSelector((state) => state.augnito.valueFromAugntio);
  const [link, setlink] = useState('');
  const handle = useFullScreenHandle();

  const dispatch = useDispatch();

  useEffect(() => {
    const inputScroll = document.getElementById('inputScroll');
    if (inputScroll) {
      inputScroll.scrollTop = inputScroll.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const validateUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_USER_IDENTITY_API}`, {
          method: 'GET',
          headers: {
            idToken: idToken,
            accessToken: accessToken,
          },
        });
        if (!response.ok) {
          console.error('User validation failed:', response.status);
          return;
        }
        const userData = await response.json();
        dispatch(loginSuccess(userData.user));
      } catch (error) {
        console.error('Error validating user:', error);
      }
    };

    if (accessToken && idToken) {
      validateUser();
    }
  }, [accessToken, idToken]);

  if (!isChatBotActive) {
    const modal = document.getElementById('my_modal_3');
    if (modal) {
      modal.showModal();
    }
  }

  

  return (
    <div className="App">
      {!user?.user ? (
        <Login />
      ) : (
        <div className="h-screen overflow-hidden max-w- mx-auto flex flex-col">
          {/* <div className=' hidden'><Dictate/></div> */}
          <div className="h-[8vh]">
            <Navbar />
          </div>
          <div className="flex-grow flex">
            <Questions />
            <div className="flex-grow flex gap-[1em]">
             <Dicom/>
              <div
                className={`w-[50%] flex-grow flex flex-col ${!isChatBotActive && isChatQuestionActive ? 'blur-sm' : ''
                  }`}
              >
                <Editor />
              </div>
            </div>
          </div>
          <div className="h-[2vh]">
            <p className="py-1 text-right">
              © Copyright 2016 - 2024 by 5C Network. All Rights Reserved.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainChatContainer;
